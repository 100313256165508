import http from '@/utils/request'

const api = {

  login(org_code: string, password: string) {
    const resp = http.post('/user/login', {org_code, password}, {
      // proxy: {
      //   protocol: 'http',
      //   host: 'media.school-live.cn',
      //   port: 80,
      //   // auth: {
      //   //   username: 'mikeymike',
      //   //   password: 'rapunz3l'
      //   // }
      // }
    })
    return resp
  },

  save_slides(slides: any) {
    // console.log('slides', )
    const resp = http.post('/auth.subtitle/save_slides', {slides: JSON.stringify(slides)})
    return resp
  },

  save_slide(index: number, slide: any) {
    // console.log('slides', slide)
    const resp = http.post('/auth.subtitle/save_slide', {index, slide: JSON.stringify(slide)})
    return resp
  },

  load_slides() {
    const resp = http.get('/auth.subtitle/load_slides')
    // console.log(resp)
    return resp
  },

  save_camera(positions: number[], slide_id: string) {
    const resp = http.post('/auth.subtitle/save_camera', {positions, slide_id})
    return resp
  },

  load_slide(oid: string, index: number) {
    const resp = http.get('/subtitle/load_slide', {params: {oid, index}})
    // console.log(resp)
    return resp
  },

}

export default api
