import {defineStore} from 'pinia'
import {IS_LOGIN} from '@/configs/user'


export interface UserState {
  username: string;
  isLogin: boolean;
}
export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    username: '',
    isLogin: false,
  }),

  getters: {
    // isLogin(state) {
    //   return localStorage.getItem(IS_LOGIN) && localStorage.getItem(IS_LOGIN) === '1'
    // }
  },

  actions: {
    login() {
      // console.log('login')
    },
    logout() {
      // console.log('666')
      this.isLogin = false
      localStorage.removeItem(IS_LOGIN)
    },
    setIsLogin() {
      this.isLogin = true
      localStorage.setItem(IS_LOGIN, '1')
    }
  },

})
