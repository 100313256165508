// 引入 axios
import axios from 'axios'
import {IS_LOGIN} from '@/configs/user'

const http = axios.create({
  baseURL: '/subtitle',
  timeout: 50000,
  // proxy: {
  //   protocol: 'http',
  //   host: 'media.school-live.cn',
  //   port: 80,
  //   // auth: {
  //   //   username: 'mikeymike',
  //   //   password: 'rapunz3l'
  //   // }
  // },
})
http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 数据请求拦截
http.interceptors.request.use((config) => {

  return config
}, (error) => {
  return Promise.reject(error)
})

// 返回响应数据拦截
http.interceptors.response.use((res) => {
  const data = res.data
  // console.log('respssss.1', data)
  // 状态码为 2xx 范围时都会调用该函数，处理响应数据
  if (res.status === 200) {
    const code = data.code
  }

  return res
}, (error) => {
  // console.log('respssss.2', error)
  if (error.response.status) {
    // 状态码超过 2xx 范围时都会调用该函数，处理错误响应
    switch (error.response.status) {
      case 403:
        localStorage.removeItem(IS_LOGIN)
        location.reload()
        break
      case 404:
        // Toast.error({
        //   type: 'error',
        //   message: '请求路径找不到！',
        //   showClose: true
        // })
        break
      case 502:
        // Toast.error({
        //   type: 'error',
        //   message: '服务器内部报错！',
        //   showClose: true
        // })
        break
      default:
        break
    }
  }
  return Promise.reject(error)
})

// 这是一位大佬指点的方法，更加简单
export default http
